var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: { title: "编辑用户分数", width: 500 },
      on: { cancel: _vm.handleCancel, ok: _vm.handleConfirm },
      model: {
        value: _vm.showPopup,
        callback: function($$v) {
          _vm.showPopup = $$v
        },
        expression: "showPopup"
      }
    },
    [
      _c(
        "div",
        { staticClass: "popup-content" },
        [
          _c(
            "a-form-model",
            _vm._b({}, "a-form-model", _vm.layout, false),
            [
              _c(
                "a-form-item",
                { attrs: { label: "操作分数类型" } },
                [
                  _c(
                    "a-select",
                    {
                      staticClass: "w-300",
                      attrs: { allowClear: "", placeholder: "操作分数类型" },
                      on: {
                        change: function($event) {
                          return _vm.handleChangeCreditType($event)
                        }
                      },
                      model: {
                        value: _vm.params.creditType,
                        callback: function($$v) {
                          _vm.$set(_vm.params, "creditType", $$v)
                        },
                        expression: "params.creditType"
                      }
                    },
                    _vm._l(_vm.shopPointsEditorTypeList, function(item) {
                      return _c(
                        "a-select-option",
                        { key: item.value, attrs: { value: item.value } },
                        [_vm._v(_vm._s(item.name))]
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _vm.params.creditType === "platformCustom"
                ? _c(
                    "a-form-item",
                    { attrs: { label: "变动分数" } },
                    [
                      _c("a-input", {
                        staticClass: "w-200",
                        attrs: {
                          allowClear: "",
                          type: "number",
                          placeholder: "变动分数"
                        },
                        model: {
                          value: _vm.params.creditScore,
                          callback: function($$v) {
                            _vm.$set(_vm.params, "creditScore", $$v)
                          },
                          expression: "params.creditScore"
                        }
                      }),
                      _c("div", { staticClass: "color-red" }, [
                        _vm._v("扣分请在分数前添加：'-'符号，如：-10，-0.5")
                      ]),
                      _c("div", { staticClass: "color-red" }, [
                        _vm._v("加分正常填入数字即可，如：10，0.5")
                      ])
                    ],
                    1
                  )
                : _vm._e(),
              _vm.params.creditType === "platformCustom"
                ? _c(
                    "a-form-item",
                    { attrs: { label: "变动分数说明" } },
                    [
                      _c("a-input", {
                        staticClass: "w-200",
                        attrs: { allowClear: "", placeholder: "变动分数说明" },
                        model: {
                          value: _vm.params.creditDesc,
                          callback: function($$v) {
                            _vm.$set(_vm.params, "creditDesc", $$v)
                          },
                          expression: "params.creditDesc"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }