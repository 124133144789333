export default {
    data() {
        return {

        }
    },
    methods: {
        /** 展示大图 */
        handleShowBigImg(imgList, current) {
            const list = imgList.map((el) => {
                return {img_url: el};
            });
            this.$previewImg({
                current: current,
                list: list,
                baseImgField: "img_url",
                showMute: false,
            });
        }
    }
}