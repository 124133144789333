<template>
  <a-drawer title="占用保证金明细" placement="right" :width="'80vw'" :closable="true" :visible="showPopup" @close="onClose">
    <a-table
        :columns="tableList"
        :pagination="pagination"
        :data-source="dataList"
        :rowKey="(record, index) => index"
        @change="handleChangePage"
    >
<!--   占用金额   -->
      <div slot="itemPriceSlot" slot-scope="row">{{ row.occupyPrice }}</div>
<!--   拍品信息   -->
      <div slot="itemProductSlot" slot-scope="row">
        <div class="w-100-w h-50 flex-center-center" @click="handleShowBigImg([row.detailInfo.coverPicture], 0)">
          <img class="w-50 h-50" :src="row.detailInfo.coverPicture" alt="">
        </div>
        <div class="mt-10">{{ row.detailInfo.productName }}</div>
      </div>
<!--   订单号   -->
      <div slot="itemOrderNoSlot" slot-scope="row">
        <div class="cur-pot color-blue" @click="handleGoToOrderList(row.detailInfo.orderNo)">{{ row.detailInfo.orderNo }}</div>
      </div>
    </a-table>
  </a-drawer>
</template>
<script>
import {bondPriceLineListTableColumns} from "@/views/cmsPage/dqPlatform/components/UserBondPriceLinePopup/_data"
import {getBondPriceOccupationLineApi} from "@/views/cmsPage/dqPlatform/_apis"
import _showBigImgMixin from "@/_mixins/_showBigImgMixin"
import {goToOtherPage} from "@/untils/otherEvent"

export default {
  components: {},
  mixins: [_showBigImgMixin],
  data() {
    return {
      showPopup: false,
      tableList: bondPriceLineListTableColumns,
      dataList: [],
      params: {
        userId: undefined,
        pageSize: 10,
        pageNum: 1,
      },
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
        showTotal: (total) => `共${total}条`,
      },
    }
  },
  computed: {
  },
  methods: {
    /** 去往订单详情页 */
    handleGoToOrderList(orderNo) {
      goToOtherPage(`/cms/dqplatform/newordermannage?orderNo=${orderNo}`)
    },
    async show(data) {
      this.params.userId = data.userId
      await this.getLine()
      this.showPopup = true
    },
    /** 获取保证金占用明细数据 */
    async getLine() {
      this.$loading.show()
      const res = await getBondPriceOccupationLineApi(this.params)
      this.$loading.hide()
      if (res.status !== '200') return
      this.dataList = res.data.records;
      this.pagination.total = res.data.total;
    },
    /** 翻页 */
    handleChangePage(page) {
      this.$set(this.pagination, "current", page);
      this.$set(this.params, "pageNum", page);
      this.getList();
    },
    onClose() {
      this.showPopup = false
    }
  }
}
</script>
<style lang="scss" scoped>
.img-list {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  img {
    width: 50px;
  }
}
.color-green {
  color: green;
}
.color-red {
  color: red;
}
</style>