import {objArrMapObj} from "@/untils/otherEvent"

export const LIAN_LIAN_SHOP_STATUS_TYPE_LIST = [
    {name: '已登记或开户失败', value: 'ACTIVATE_PENDING'},
    {name: '审核中', value: 'CHECK_PENDING'},
    {name: '审核通过', value: 'REMITTANCE_VALID_PENDING'},
    {name: '待激活', value: 'ACTIVATE_PENDING_NEW'},
    {name: '正常', value: 'NORMAL'},
]

export const LIAN_LIAN_USER_STATUS_CLASS_TYPE = {
    ACTIVATE_PENDING: 'color-red',
    CHECK_PENDING: 'color-gray',
    REMITTANCE_VALID_PENDING: 'color-orange',
    ACTIVATE_PENDING_NEW: 'color-blue',
    NORMAL: 'color-green'
}

export const lianLianStatusTypeMapText = (value) => {
    return objArrMapObj('value', 'name', LIAN_LIAN_SHOP_STATUS_TYPE_LIST)[value]
}

/** 店铺指标 1、成交排行 2、瑕疵退款率 3、售后纠纷率 4、精品率  5、0元流拍率 6、驳回 */
export const shopQuotaTypeMapTitleText = {
    1: '成交排行',
    2: '瑕疵退款率',
    3: '售后纠纷率',
    4: '精品率',
    5: '0元流拍率',
    6: '驳回',
}

/** 店铺分数操作类型
 * —-increaseTraffic：平台操作-站外引流
 * —-AbusiveCustomer：平台操作-辱骂客户
 * —-platformCustom：平台操作-自定义
 * */
export const shopPointsEditorTypeList = [
    { name: '站外引流', value: 'increaseTraffic' },
    { name: '辱骂客户', value: 'AbusiveCustomer' },
    { name: '自定义', value: 'platformCustom' },
]