var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-drawer",
    {
      attrs: {
        title: "出价记录",
        placement: "right",
        width: "80%",
        visible: _vm.showPopup
      },
      on: { close: _vm.handleClosePopup }
    },
    [
      _vm.shopPointsCountData
        ? _c(
            "div",
            {},
            [
              _c("a-card", { attrs: { title: "用户店铺分数" } }, [
                _c("div", { staticClass: "w-100-w flex-sp-bt-center" }, [
                  _c("div", { staticClass: "flex-start-center" }, [
                    _c("div", {}, [
                      _vm._v("店铺当前分数："),
                      _c(
                        "span",
                        {
                          staticClass:
                            "font-weight-bold color-blue font-size-16"
                        },
                        [_vm._v(_vm._s(_vm.shopPointsCountData.shopPoints))]
                      ),
                      _vm._v("分")
                    ]),
                    _c("div", { staticClass: "ml-40" }, [
                      _vm._v("本月累计"),
                      _c(
                        "span",
                        {
                          staticClass:
                            "font-weight-bold color-green font-size-16"
                        },
                        [
                          _vm._v(
                            "加分" +
                              _vm._s(_vm.shopPointsCountData.monthAddPoints)
                          )
                        ]
                      ),
                      _vm._v("分")
                    ]),
                    _c("div", { staticClass: "ml-40" }, [
                      _vm._v("本月累计"),
                      _c(
                        "span",
                        {
                          staticClass: "font-weight-bold color-red font-size-16"
                        },
                        [
                          _vm._v(
                            "扣分" +
                              _vm._s(_vm.shopPointsCountData.monthCutPoints)
                          )
                        ]
                      ),
                      _vm._v("分")
                    ])
                  ]),
                  _c(
                    "div",
                    { staticClass: "flex-center-center" },
                    [
                      _c(
                        "a-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.handleEditorShopPoints()
                            }
                          }
                        },
                        [_vm._v("编辑店铺分数")]
                      )
                    ],
                    1
                  )
                ]),
                _vm.shopPointsCountData.shopWarningList &&
                _vm.shopPointsCountData.shopWarningList.length
                  ? _c(
                      "div",
                      { staticClass: "mt-20" },
                      [
                        _c("a-divider", [_vm._v("店铺受限")]),
                        _vm._l(
                          _vm.shopPointsCountData.shopWarningList,
                          function(item, index) {
                            return _c(
                              "div",
                              {
                                key: index,
                                staticClass:
                                  "w-100-w flex-sp-bt-center mt-20 bb-1"
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "flex-start-center" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "font-weight-bold color-blue"
                                      },
                                      [_vm._v("（" + _vm._s(index + 1) + "）")]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "w-500 font-weight-bold color-red"
                                      },
                                      [_vm._v(_vm._s(item.warningTitle))]
                                    )
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "ml-40 font-weight-bold color-gray"
                                  },
                                  [_vm._v(_vm._s(item.warningTime))]
                                )
                              ]
                            )
                          }
                        )
                      ],
                      2
                    )
                  : _vm._e()
              ])
            ],
            1
          )
        : _vm._e(),
      _c("a-divider", [_vm._v("加分记录")]),
      _c("AddOrCutPointsTableList", {
        attrs: {
          listType: "addList",
          list: _vm.addPointsList,
          pagination: _vm.addPointsPagination
        },
        on: {
          changePage: function($event) {
            return _vm.handleChangeAddOrCutPointsPage($event, "add")
          }
        }
      }),
      _c("a-divider", { staticClass: "mt-20" }, [_vm._v("处罚/扣分记录")]),
      _c("AddOrCutPointsTableList", {
        attrs: {
          listType: "cutList",
          list: _vm.cutPointsList,
          pagination: _vm.cutPointsPagination
        },
        on: {
          changePage: function($event) {
            return _vm.handleChangeAddOrCutPointsPage($event, "cut")
          }
        }
      }),
      _c("a-divider", { staticClass: "mt-20" }, [_vm._v("下架店铺所有商品")]),
      _c(
        "div",
        { staticClass: "mt-20" },
        [
          _c(
            "a-button",
            {
              attrs: { type: "danger" },
              on: { click: _vm.handleTakeOffShopAllProduct }
            },
            [_vm._v("下架店铺所有商品")]
          )
        ],
        1
      ),
      _c("ShopPointsEditorPopup", {
        ref: "shopPointsEditorPopupEl",
        on: { success: _vm.handleEditorSuccess }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }