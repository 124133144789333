<template>
  <a-table
      :columns="tableList"
      :pagination="pagination"
      :data-source="list"
      :rowKey="(record, index) => index"
      @change="changePage"
  >
<!--  标题分数  -->
    <div slot="itemPointsTitleSlot" slot-scope="row">
      <div class="font-weight-bold color-black">{{ row.titleText }}</div>
      <div
          class="mt-10 font-weight-bold font-size-20"
          :class="listType === 'addList' ? 'color-green' : 'color-red'"
      >{{ listType === 'addList' ? `+${row.points}` : row.points }}</div>
    </div>
<!--  原因  -->
    <div slot="itemRemarkTextSlot" slot-scope="row">
      <div v-if="row.creditDesc">原因：{{ row.creditDesc }}</div>
      <div v-if="row.remarkText">{{ row.remarkText }}</div>
    </div>
<!--  关联订单  -->
    <div slot="itemTieUpOrderNoSlot" slot-scope="row">
      <div
          v-if="row.productInfo"
          class="font-weight-bold cur-pot color-blue"
          @click="handleGoToOrderList(row.productInfo.orderNo)"
      >{{ row.productInfo.orderNo }}</div>
    </div>
  </a-table>
</template>
<script>
import {goToOtherPage} from "@/untils/otherEvent"
import {addOrCutPointsTableColumns} from "@/views/cmsPage/shopManage/shopInfoList/_components/ShopPointsPopup/_data"

export default {
  props: ['pagination', 'list', 'listType'],
  emits: ['changePage'],
  data() {
    return {
      tableList: addOrCutPointsTableColumns,
    }
  },
  methods: {
    /** 去往订单列表 */
    handleGoToOrderList(orderNo) {
      goToOtherPage(`/cms/dqplatform/newordermannage?orderNo=${orderNo}`)
    },
    /** 翻页 */
    changePage(page) {
      this.$emit("changePage", page);
    },
  }
}
</script>
<style lang="scss" scoped>
</style>