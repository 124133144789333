var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("a-table", {
    attrs: {
      columns: _vm.tableList,
      pagination: _vm.pagination,
      "data-source": _vm.list,
      rowKey: function(record, index) {
        return index
      }
    },
    on: { change: _vm.changePage },
    scopedSlots: _vm._u([
      {
        key: "itemPointsTitleSlot",
        fn: function(row) {
          return _c("div", {}, [
            _c("div", { staticClass: "font-weight-bold color-black" }, [
              _vm._v(_vm._s(row.titleText))
            ]),
            _c(
              "div",
              {
                staticClass: "mt-10 font-weight-bold font-size-20",
                class: _vm.listType === "addList" ? "color-green" : "color-red"
              },
              [
                _vm._v(
                  _vm._s(
                    _vm.listType === "addList" ? "+" + row.points : row.points
                  )
                )
              ]
            )
          ])
        }
      },
      {
        key: "itemRemarkTextSlot",
        fn: function(row) {
          return _c("div", {}, [
            row.creditDesc
              ? _c("div", [_vm._v("原因：" + _vm._s(row.creditDesc))])
              : _vm._e(),
            row.remarkText
              ? _c("div", [_vm._v(_vm._s(row.remarkText))])
              : _vm._e()
          ])
        }
      },
      {
        key: "itemTieUpOrderNoSlot",
        fn: function(row) {
          return _c("div", {}, [
            row.productInfo
              ? _c(
                  "div",
                  {
                    staticClass: "font-weight-bold cur-pot color-blue",
                    on: {
                      click: function($event) {
                        return _vm.handleGoToOrderList(row.productInfo.orderNo)
                      }
                    }
                  },
                  [_vm._v(_vm._s(row.productInfo.orderNo))]
                )
              : _vm._e()
          ])
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }