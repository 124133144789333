import instance from "@/axios"

/** 获取物流信息 */
export const postUpdateUserCardInfo = async (params) => {
    return instance({
        url: '/dq_admin/userShopInfo/modifyLianLianUserInfo',
        method: 'post',
        data: params
    })
}
/** 获取相同用户的店铺 */
export const getSameShopByUserId = async (params) => {
    return instance({
        url: '/dq_admin/userShopInfo/sameIdentityCardNoShopList',
        method: 'get',
        params: params
    })
}

/** 获取店铺指标 */
export const getShopQuotaListApi = async (params) => {
    return instance({
        url: '/dq_admin/userShopInfoQuota/shopQuota',
        method: 'get',
        params: params
    })
}
/** 获取店铺报表 */
export const getShopStatementListApi = async (params) => {
    return instance({
        url: '/dq_admin/userShopInfoQuota/shopStatement',
        method: 'get',
        params: params
    })
}

/** 编辑用户的店铺分数 */
export const postShopPointsEditorApi = async (params) => {
    return instance({
        url: '/dq_admin/userCreditDetail/addOrSubtract',
        method: 'post',
        data: params
    })
}

/** 获取店铺分数统计 */
export const getShopPointsCountDataApi = async (params) => {
    return instance({
        url: '/dq_admin/userCredit/getScoreHead',
        method: 'get',
        params: params
    })
}

/** 获取店铺分数列表---加分 */
export const getShopPointsByAddListApi = async (params) => {
    return instance({
        url: '/dq_admin/userCreditDetail/getAddScoreList',
        method: 'get',
        params: params
    })
}
/** 获取店铺分数列表---处罚/扣分 */
export const getShopPointsByCutListApi = async (params) => {
    return instance({
        url: '/dq_admin/userCreditDetail/getSubtractScoreList',
        method: 'get',
        params: params
    })
}

/** 下架店铺所有商品 */
export const getTakeOffShopAllProductApi = async (params) => {
    return instance({
        url: '/dq_admin/userCreditDetail/soldOutAllProductV1',
        method: 'get',
        params: params
    })
}