<template>
  <a-modal v-model="showPopup" title="编辑用户分数" :width="500" @cancel="handleCancel" @ok="handleConfirm">
    <div class="popup-content">
      <a-form-model v-bind="layout">
        <a-form-item label="操作分数类型">
          <a-select
              allowClear
              v-model="params.creditType"
              class="w-300"
              placeholder="操作分数类型"
              @change="handleChangeCreditType($event)"
          >
            <a-select-option
                v-for="item in shopPointsEditorTypeList"
                :key="item.value"
                :value="item.value"
            >{{ item.name }}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="变动分数" v-if="params.creditType === 'platformCustom'">
          <a-input
              allowClear
              v-model="params.creditScore"
              class="w-200"
              type="number"
              placeholder='变动分数'
          ></a-input>
          <div class="color-red">扣分请在分数前添加：'-'符号，如：-10，-0.5</div>
          <div class="color-red">加分正常填入数字即可，如：10，0.5</div>
        </a-form-item>
        <a-form-item label="变动分数说明" v-if="params.creditType === 'platformCustom'">
          <a-input
              allowClear
              v-model="params.creditDesc"
              class="w-200"
              placeholder='变动分数说明'
          ></a-input>
        </a-form-item>
      </a-form-model>
    </div>
  </a-modal>
</template>
<script>
import {postShopPointsEditorApi} from "@/views/cmsPage/shopManage/shopInfoList/_apis"
import {shopPointsEditorTypeList} from "@/views/cmsPage/shopManage/shopInfoList/_data"

export default {
  emits: ['success'],
  data() {
    return {
      showPopup: false,
      layout: {
        labelCol: { span: 6 }, //标签占5份  共24份
        wrapperCol: { span: 18 },
      },
      shopPointsEditorTypeList,
      params: {
        userId: undefined,
        creditType: undefined, // 平台操作类型
        creditScore: undefined, // 扣除，增加分数（+ 增加，- 扣除）
        creditDesc: undefined, // 分数操作描述
      },
      currentShopPoints: undefined,
    }
  },
  methods: {
    /** 展示弹窗 */
    show(userId, shopPoints) {
      this.params.userId = userId
      this.currentShopPoints = shopPoints
      this.showPopup = true
    },
    /** 取消 */
    handleCancel() {
      this.params = {
        userId: undefined,
        creditType: undefined, // 平台操作类型
        creditScore: undefined, // 扣除，增加分数（+ 增加，- 扣除）
        creditDesc: undefined, // 分数操作描述
      }
      this.showPopup = false
    },
    /** 确定 */
    async handleConfirm() {
      if (!this.params.creditType) return this.$message.warning('请选择编辑分数类型')
      if (this.params.creditType === 'platformCustom') {
        if (!this.params.creditScore) return this.$message.warning('请输入需要变动的分数')
        const changePoints = Number(this.params.creditScore) + Number(this.currentShopPoints)
        if (changePoints > 100 || changePoints < 0) return this.$message.warning(`分数变动范围不能小于0，或大于100，此次变动分数结果为：${changePoints}`)
        if (!this.params.creditDesc) return this.$message.warning('请输入需要变动的分数说明')
      }
      this.$loading.show()
      const res = await postShopPointsEditorApi(this.params)
      this.$loading.hide()
      if (res.status !== '200') return
      this.$emit('success')
      this.handleCancel()
    },
    /** 改变类型 */
    handleChangeCreditType(e) {
      if (e !== 'platformCustom') {
        this.params.creditScore = undefined
        this.params.creditDesc = undefined
      }
    },
  }
}
</script>