<template>
  <a-drawer title="出价记录" placement="right" width="80%" :visible="showPopup" @close="handleClosePopup">
<!--  顶部统计  -->
    <div class='' v-if="shopPointsCountData">
      <a-card :title="`用户店铺分数`">
        <div class="w-100-w flex-sp-bt-center">
          <div class="flex-start-center">
            <div class="">店铺当前分数：<span class="font-weight-bold color-blue font-size-16">{{ shopPointsCountData.shopPoints }}</span>分</div>
            <div class="ml-40">本月累计<span class="font-weight-bold color-green font-size-16">加分{{ shopPointsCountData.monthAddPoints }}</span>分</div>
            <div class="ml-40">本月累计<span class="font-weight-bold color-red font-size-16">扣分{{ shopPointsCountData.monthCutPoints }}</span>分</div>
          </div>
          <div class="flex-center-center">
            <a-button type="primary" @click="handleEditorShopPoints()">编辑店铺分数</a-button>
          </div>
        </div>
        <div class="mt-20" v-if="shopPointsCountData.shopWarningList && shopPointsCountData.shopWarningList.length">
          <a-divider>店铺受限</a-divider>
          <div class="w-100-w flex-sp-bt-center mt-20 bb-1" v-for="(item, index) in shopPointsCountData.shopWarningList" :key="index">
            <div class="flex-start-center">
              <div class="font-weight-bold color-blue">（{{ index + 1 }}）</div>
              <div class="w-500 font-weight-bold color-red">{{ item.warningTitle }}</div>
            </div>
            <div class="ml-40 font-weight-bold color-gray">{{ item.warningTime }}</div>
          </div>
        </div>
      </a-card>
    </div>
<!--  加分记录  -->
    <a-divider>加分记录</a-divider>
    <AddOrCutPointsTableList
        listType="addList"
        :list="addPointsList"
        :pagination="addPointsPagination"
        @changePage="handleChangeAddOrCutPointsPage($event, 'add')"
    />
<!--  处罚/扣分记录  -->
    <a-divider class="mt-20">处罚/扣分记录</a-divider>
    <AddOrCutPointsTableList
        listType="cutList"
        :list="cutPointsList"
        :pagination="cutPointsPagination"
        @changePage="handleChangeAddOrCutPointsPage($event, 'cut')"
    />
<!--  下架店铺所有商品  -->
    <a-divider class="mt-20">下架店铺所有商品</a-divider>
    <div class="mt-20">
      <a-button type="danger" @click="handleTakeOffShopAllProduct">下架店铺所有商品</a-button>
    </div>
<!--  店铺分数编辑  -->
    <ShopPointsEditorPopup ref="shopPointsEditorPopupEl" @success="handleEditorSuccess"/>
  </a-drawer>
</template>
<script>
import {
  getShopPointsByAddListApi,
  getShopPointsByCutListApi,
  getShopPointsCountDataApi, getTakeOffShopAllProductApi
} from "@/views/cmsPage/shopManage/shopInfoList/_apis"
import AddOrCutPointsTableList
  from "@/views/cmsPage/shopManage/shopInfoList/_components/ShopPointsPopup/_components/AddOrCutPointsTableList/index.vue"
import ShopPointsEditorPopup from "@/views/cmsPage/shopManage/shopInfoList/_components/ShopPointsEditorPopup/index.vue"

export default {
  components: {
    AddOrCutPointsTableList, ShopPointsEditorPopup
  },
  data() {
    return {
      showPopup: false,
      productId: undefined,
      addPointsParams: {
        userId: undefined,
        pageSize: 5,
        pageNum: 1,
      },
      addPointsList: [], // 当前出价记录
      addPointsPagination: {
        total: 0,
        current: 1,
        pageSize: 5,
        showTotal: (total) => `共${total}条`,
      },
      cutPointsParams: {
        userId: undefined,
        pageSize: 5,
        pageNum: 1,
      },
      cutPointsList: [], // 历史出价记录
      cutPointsPagination: {
        total: 0,
        current: 1,
        pageSize: 5,
        showTotal: (total) => `共${total}条`,
      },
      userId: undefined,
      shopPointsCountData: undefined
    }
  },
  methods: {
    /** 下架店铺所有商品 */
    async handleTakeOffShopAllProduct() {
      this.$confirm({
        title: '确定下架店铺所有商品？',
        content: '下架后，店铺所有商品将无法再销售，请谨慎操作！！！！',
        okText: '确定',
        cancelText: '取消',
        onOk: async () => {
          this.$loading.show()
          const res = await getTakeOffShopAllProductApi({userId: this.userId})
          this.$loading.hide()
          if (res.status !== '200') return
          this.$message.success('下架成功')
        }
      })
    },
    /** 编辑店铺分数 */
    handleEditorShopPoints() {
      this.$refs.shopPointsEditorPopupEl.show(this.userId, this.shopPointsCountData.shopPoints)
    },
    async show(userId) {
      this.userId = userId
      this.addPointsParams.userId = userId
      this.cutPointsParams.userId = userId
      await this.getShopPointsCountData()
      await this.getShopPointsByAddList()
      await this.getShopPointsByCutList()
      this.showPopup = true
    },
    /** 获取分数统计数据 */
    async getShopPointsCountData() {
      this.$loading.show()
      const res = await getShopPointsCountDataApi({userId: this.userId})
      this.$loading.hide()
      if (res.status !== '200') return
      this.shopPointsCountData = res.data
    },
    /** 获取加分记录 */
    async getShopPointsByAddList() {
      this.$loading.show()
      const res = await getShopPointsByAddListApi(this.addPointsParams)
      this.$loading.hide()
      if (res.status !== '200') return
      this.addPointsList = res.data.records
      this.addPointsPagination.total = res.data.total
    },
    /** 获取扣分记录 */
    async getShopPointsByCutList() {
      this.$loading.show()
      const res = await getShopPointsByCutListApi(this.cutPointsParams)
      this.$loading.hide()
      if (res.status !== '200') return
      this.cutPointsList = res.data.records
      this.cutPointsPagination.total = res.data.total
    },
    /** 翻页 */
    async handleChangeAddOrCutPointsPage(page, type) {
      const { current } = page
      if (type === 'add') {
        this.$set(this.addPointsPagination, "current", current);
        this.$set(this.addPointsParams, "pageNum", current);
        await this.getShopPointsByAddList()
      } else if (type === 'cut') {
        this.$set(this.cutPointsPagination, "current", current);
        this.$set(this.cutPointsParams, "pageNum", current);
        await this.getShopPointsByCutList()
      }
    },
    /** 关闭弹窗 */
    handleClosePopup() {
      this.$set(this.addPointsPagination, "current", 1);
      this.$set(this.addPointsParams, "pageNum", 1);
      this.$set(this.cutPointsPagination, "current", 1);
      this.$set(this.cutPointsParams, "pageNum", 1);
      this.showPopup = false
    },
    /** 编辑店铺分数成功 */
    async handleEditorSuccess() {
      await this.getShopPointsCountData()
      this.$set(this.addPointsPagination, "current", 1);
      this.$set(this.addPointsParams, "pageNum", 1);
      this.$set(this.cutPointsPagination, "current", 1);
      this.$set(this.cutPointsParams, "pageNum", 1);
      await this.getShopPointsByAddList()
      await this.getShopPointsByCutList()
    }
  }
}
</script>